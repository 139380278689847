import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { AxiosResponse } from "axios";

/**
 * Ce composant permet de télécharger sous forme de fichier la donnée qui est
 * renvoyée par la propriété getter.
 *
 * En utilisant axios si l'on veut que ça fonctionne correctement, il faut
 * penser à changer la propriété `responseType` en `blob`.
 *
 * Dans le contexte de Neko-paie il faut utiliser les méthodes finissant par
 * `wrap` parce qu'elles retournent l'objet `AxiosResponse`.
 *
 * @example
 * return AdministrationBillingResourceApi.listWrap(this.year, {
 * 	headers: { accept: "text/csv" },
 * 	responseType: "blob"
 * });
 *
 * @see AxiosResponse
 */
@Component
export default class DownloadButtonComponent extends Vue {
	@Prop({ required: true })
	public readonly getter!: () => Promise<AxiosResponse<any>>;

	@Prop({ default: "" })
	public readonly filename!: string;

	public downloading: boolean = false;

	public async click() {
		this.$emit("download");
		this.downloading = true;

		try {
			const response = await this.getter();
			const link = document.createElement("a");
			document.body.appendChild(link);

			if (this.filename) {
				link.download = this.filename;
			} else if (response.headers["content-disposition"]) {
				// tslint:disable-next-line
				link.download = response.headers["content-disposition"].replace(new RegExp('.* filename="(.*)".*'), "$1");
			}

			if (typeof response.data === "string") {
				link.href = response.data
			} else {
				link.href = window.URL.createObjectURL(response.data);
			}

			link.onclick = e => e.stopPropagation();
			link.click();
			link.remove();
			this.$emit("downloaded");
		} catch (e) {
			this.$message.error(this.$t("downloading.error") as string);
		} finally {
			this.downloading = false;
		}
	}
}
